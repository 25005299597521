import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import {
  TechnologyFeatures,
  WhyUs,
  MultiColumnList,
} from '@components/technologies';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/technologies';

class Laravel extends Component {
  render() {
    const {
      title,
      overview,
      headerImage,
      technologyFeatures,
      whyTechnology,
      whyUs,
      services,
    } = pageContent.subPage.laravel;
    return (
      <Layout>
        <PageHeader image={headerImage} />

        <Heading title={title} overview={overview} noHeadingIcon={true} />

        <TechnologyFeatures content={technologyFeatures} />

        <MultiColumnList
          content={whyTechnology}
          listStyle={'dot'}
          listItemTextBold={true}
        />

        <WhyUs content={whyUs} />

        <MultiColumnList
          content={services}
          listStyle={'dot'}
          listItemTextBold={true}
        />

        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default Laravel;
